import isProduction from "../isStagingOrProduction";
import { ResponseOK } from "../../typeScriptTypes/TypeScriptTypes";
import { authFetch } from "../authFetch";

let baseUrl = "";

if (isProduction) {
  baseUrl = `${process.env.REACT_APP_PRODUCTION_BASE}/api/v1/auth`;
} else {
  baseUrl = `${process.env.REACT_APP_LOCAL_BASE}/api/v1/auth`;
}

// @desc Register a new User
// @access Private
export const postRegisterNewUser = (
  username: string,
  password: string,
  email: string,
  timeZoneName: string
) => {
  return authFetch(`${baseUrl}/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user: {
        username: username,
        password: password,
        email: email,
        timezone: timeZoneName,
      },
    }),
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};

// @desc Patch new email verification value
// @access Private
export const resendEmailVerification = (token: string) => {
  return authFetch(`${baseUrl}/resend-email-verification`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};

// @desc Login an existing user
// @access Private
export const loginUser = (email: string, password: string) => {
  return authFetch(`${baseUrl}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};

// @desc Forgotten password
// @access Public
export const forgotpassword = (email: string) => {
  return authFetch(`${baseUrl}/forgotpassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
    }),
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};

// @desc Update a users details
// @access Private
export const updatePassword = (reset_token: string, newPassword: string) => {
  return authFetch(`${baseUrl}/forgotpassword`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resetToken: reset_token,
      newPassword: newPassword,
    }),
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};

// @desc verify an email address through the hash token.
// @access Public
export const patchEmailVerification = (emailvalidation_token: string) => {
  return authFetch(`${baseUrl}/emailverification`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      emailVerificationToken: emailvalidation_token,
    }),
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};

// @desc Send another email to verification email address through the hash token.
// @access Public
export const newemailverification = (email: string) => {
  return authFetch(`${baseUrl}/newemailverification`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
    }),
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};

// @desc Get the details of the current logged in user
// @access Private
/* export const getMe = (token: string) => {
  return authFetch(`${baseUrl}/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response: ResponseOK) => {
      return response.json().then((data: any) => {
        if (!response.ok) {
          throw new Error(
            data.msg || "An error occurred while fetching the data."
          );
        }
        return data;
      });
    })
    .then((data) => {
      const myResponse: ResponseOK = {
        body: data,
        status: data.status,
        statusText: data.statusText,
        json: () => Promise.resolve(data),
        ok: true,
      };
      return myResponse;
    });
}; */

// @desc Get a users email address
// @route GET /api/v1/auth/email-address
export const getLoggedInUserEmail = (token: string) => {
  return authFetch(`${baseUrl}/email-address`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};

// @desc PATCH update a users settings
// @access Private
/* export const patchUserSettings = (
  profile_id: string,
  avatar_url: string,
  email?: string,
  default_time_zone?: string
) => {
  return authFetch(`${baseUrl}/usersettings/${profile_id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user: {
        email: email,
        avatar_url: avatar_url,
        default_time_zone: default_time_zone,
      },
    }),
  })
    .then((response: ResponseOK) => {
      return response.json().then((data: any) => {
        if (!response.ok) {
          throw new Error(
            data.msg || "An error occurred while fetching the data."
          );
        }
        return data;
      });
    })
    .then((data) => {
      const myResponse: ResponseOK = {
        body: data,
        status: data.status,
        statusText: data.statusText,
        json: () => Promise.resolve(data),
        ok: true,
      };
      return myResponse;
    });
}; */

// @desc Get a boolean if user is admin
// @route GET /api/v1/auth/is-user-admin
// @access Private
export const getUserIsAdmin = (token: string) => {
  return authFetch(`${baseUrl}/is-user-admin`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};
